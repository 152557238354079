body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.fd-spinner.page-load-spinner {
  margin-top: calc(50vh - 60px);
}
.fd-shell {
  height: auto !important;
}
.fd-app .fd-app__navigation--vertical {
  overflow-y: auto;
  -webkit-box-flex: auto;
  -ms-flex: 0;
  flex: auto;
}
.list-view .fd-panel__body {
  padding: 0;
}
.list-view .fd-panel__actions > button,
.list-view .fd-panel__actions .fd-button-group {
  margin-left: 8px;
}
.detail-view .form-text,
.edit-view .form-text {
  color: var(--fd-color-text-1);
}
.detail-view .amount,
.edit-view .amount {
  color: #666666;
  font-family: '72', sans-serif;
  font-weight: 800;
  font-size: 2.2rem !important;
}
.detail-view .unit,
.edit-view .unit {
  color: #787777;
  font-family: '72', sans-serif;
  font-weight: bold;
  font-size: 1rem !important;
}
.detail-view .fd-tree__col--control,
.edit-view .fd-tree__col--control {
  width: 50px;
}
.detail-view .fd-tree__row--header,
.edit-view .fd-tree__row--header {
  background-color: #fff;
}
.fd-page .page-title {
  margin: 12px 0;
}
.home-welcome-page {
  text-align: center;
}
.home-welcome-page .app {
  text-align: center;
}
.home-welcome-page .rekit-logo {
  animation: app-logo-spin infinite 10s linear;
  height: 80px;
}
.home-welcome-page .app-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
  overflow: hidden;
  position: relative;
}
.home-welcome-page .app-title {
  font-size: 1.5em;
}
.home-welcome-page .app-intro {
  font-size: large;
}
.home-welcome-page ul,
.home-welcome-page li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.home-welcome-page ul {
  margin-top: 20px;
}
.home-welcome-page li {
  margin-top: 10px;
}
.home-welcome-page a {
  color: #0288d1;
  text-decoration: none;
}
.home-welcome-page a:hover {
  text-decoration: underline;
}
.home-welcome-page p.memo {
  width: 500px;
  color: #999;
  font-size: 12px;
  line-height: 150%;
  margin: auto;
}
@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#canvas-title {
  width: 1200px;
  margin: 20px auto;
  padding: 0;
}
.main-canvas {
  width: 1200px;
  margin: 0 auto;
  padding: 0;
  position: relative;
}
.main-canvas h4.sub-title {
  font-size: 20px;
  color: #999;
  margin: 20px 0 10px;
}
.main-canvas .cols-2-wrapper {
  display: flex;
  flex-direction: row;
}
.main-canvas .arch-canvas {
  width: 980px;
  background-color: #fafafa;
  padding: 30px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  order: 1;
}
.main-canvas .arch-canvas .line {
  width: 2px;
  background-color: black;
  position: absolute;
  z-index: 100;
}
.main-canvas .arch-canvas i.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
.main-canvas .arch-canvas i.arrow.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.main-canvas .arch-canvas i.arrow.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.main-canvas .arch-canvas i.arrow.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.main-canvas .arch-canvas i.arrow.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.main-canvas .arch-canvas i.arrow.hide {
  display: none;
}
.main-canvas .arch-canvas .fe-lines.fe-lines-3 .fe-line-1 {
  top: 105px;
  left: 390px;
  height: 170px;
  transform: rotate(133deg);
}
.main-canvas .arch-canvas .fe-lines.fe-lines-3 .fe-line-2 {
  top: 134px;
  left: 512px;
  height: 115px;
}
.main-canvas .arch-canvas .fe-lines.fe-lines-3 .fe-line-3 {
  top: 109px;
  left: 640px;
  height: 161px;
  transform: rotate(225deg);
}
.main-canvas .arch-canvas .be-lines.be-lines-1 .be-line-1 {
  top: 336px;
  left: 509px;
  height: 64px;
}
.main-canvas .arch-canvas .be-lines.be-lines-2 .be-line-1 {
  top: 336px;
  left: 455px;
  height: 62px;
}
.main-canvas .arch-canvas .be-lines.be-lines-2 .be-line-2 {
  top: 336px;
  left: 575px;
  height: 64px;
}
.main-canvas .arch-canvas .be-lines.be-lines-3 .be-line-1 {
  top: 310px;
  left: 395px;
  height: 110px;
  transform: rotate(231deg);
}
.main-canvas .arch-canvas .be-lines.be-lines-3 .be-line-2 {
  left: 511px;
  height: 69px;
  top: 334px;
}
.main-canvas .arch-canvas .be-lines.be-lines-3 .be-line-3 {
  left: 632px;
  height: 115px;
  top: 311px;
  transform: rotate(125deg);
}
.main-canvas .be-1 .be-arrow-1.down {
  top: -15px;
  left: 113px !important;
}
.main-canvas .be-2 .be-arrow-1 {
  top: -16px !important;
  left: 167px !important;
}
.main-canvas .be-2 .be-arrow-2 {
  left: 71px !important;
}
.main-canvas .be-3 .be-arrow-1 {
  left: 173px !important;
  top: -14px !important;
  transform: rotate(91deg) !important;
}
.main-canvas .be-3 .be-arrow-2 {
  top: -12px !important;
  left: 115px !important;
}
.main-canvas .be-3 .be-arrow-3 {
  left: 67px !important;
  top: -11px !important;
  transform: rotate(362deg) !important;
}
.main-canvas .tile-wrapper {
  width: auto;
  position: relative;
}
.main-canvas .tile-wrapper.be-tile-wrapper .arrow {
  position: absolute;
}
.main-canvas .tile-wrapper.be-tile-wrapper .arrow.down {
  top: -15px;
  left: 110px;
}
.main-canvas .frontend-area {
  min-height: 150px;
  padding: 50px 0 !important;
}
.main-canvas .bff-area {
  position: relative;
  width: 100%;
  z-index: 9;
}
.main-canvas .bff-area .bff-tile {
  position: absolute;
  left: calc(50% - 100px);
  top: -23px;
  width: 220px;
  text-align: center;
  line-height: 70px;
  margin: 0 auto;
  height: 70px;
  font-size: 16px;
  border: 4px;
  font-weight: bold;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4) !important;
  background-color: #9c27b0 !important;
  color: #fff;
  border-radius: 4px;
}
.main-canvas .bff-area .fe-arrow {
  z-index: 100;
  position: absolute;
}
.main-canvas .bff-fe-3 .fe-arrow-1 {
  left: 418px;
  top: -38px;
  transform: rotate(1deg) !important;
}
.main-canvas .bff-fe-3 .fe-arrow-2 {
  left: 478px;
  top: -39px;
}
.main-canvas .bff-fe-3 .fe-arrow-3 {
  left: 551px;
  top: -39px;
  transform: rotate(89deg) !important;
}
.main-canvas .backend-area {
  margin-top: 30px;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-canvas .dropzone {
  box-sizing: content-box !important;
  background-color: #fafafa;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  position: relative;
}
.main-canvas .dropzone h5 {
  font-size: 1rem;
  margin: 5px 0px 15px 5px;
  position: absolute;
  text-align: left;
  color: #232323;
  top: 5px;
  left: 5px;
}
.main-canvas .dropzone.is-active {
  border: 2px solid #9c27b0 !important;
}
.main-canvas .dropzone.is-started {
  border: 2px dashed #9c27b0;
}
.main-canvas .action-area {
  width: 200px;
  order: 2;
}
.main-canvas .action-area > button {
  position: relative;
  color: #fff;
  width: 200px;
  cursor: move;
  margin-left: 30px;
  margin-bottom: 15px;
  font-size: 14px;
  height: 80px;
  font-weight: bold;
  background-color: #fafafa;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.main-canvas .action-area > button .middle-icon {
  position: absolute;
  top: 12px;
  left: 90px;
}
.main-canvas .action-area > button .move-vert-icon {
  position: absolute;
  left: -6px;
  top: 20px;
  font-size: 30px;
}
.main-canvas .action-area > button > span {
  display: block;
  margin-top: 22px;
}
.main-canvas .svc-tile {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50px;
  margin: 0 0 15px 15px;
  font-size: 14px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4) !important;
  background-color: #9c27b0 !important;
  color: #fff;
  border-radius: 4px;
}
.main-canvas .submit-area {
  margin-top: 50px;
  text-align: center;
  width: 1000px;
}
.main-canvas .submit-area .main-btn {
  width: 120px;
}
.main-canvas .submit-area .normal-btn {
  margin-left: 10px;
  padding-left: 37px;
  background-color: #ebdfef;
  color: #3f51b5;
}
