@import '../../styles/mixins';

#canvas-title {
  width: 1200px;
  margin: 20px auto;
  padding: 0;
}
.main-canvas {
  // min-width: 1024px;
  // max-width: 1280px;
  // height: 600px;
  // border: #000 1px solid;
  width: 1200px;
  margin: 0 auto;
  padding: 0;
  position: relative;

  h4.sub-title {
    font-size: 20px;
    color: #999;
    margin: 20px 0 10px;
  }

  .cols-2-wrapper {
    display: flex;
    flex-direction: row;
  }

  .arch-canvas {
    width: 980px;
    background-color: #fafafa;
    padding: 30px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    order: 1;

    .line {
      width: 2px;
      // height: 100px;
      background-color: black;
      position: absolute;
      z-index: 100;
    }

    i.arrow {
      border: solid black;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      &.right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
      &.left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
      }
      &.up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }
      &.down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
      &.hide {
        display: none;
      }
    }

    .fe-lines {
      &.fe-lines-3 {
        .fe-line-1 {
          top: 105px;
          left: 390px;
          height: 170px;
          transform: rotate(133deg);
        }
        .fe-line-2 {
          top: 134px;
          left: 512px;
          height: 115px;
        }
        .fe-line-3 {
          top: 109px;
          left: 640px;
          height: 161px;
          transform: rotate(225deg);
        }
      }
    }

    .be-lines {
      &.be-lines-1 {
        .be-line-1 {
          // top: 336px;
          // left: 609px;
          // height: 53px;

          //dev
          // top: 336px;
          // left: 516px;
          // height: 68px;

          //prod
          top: 336px;
          left: 509px;
          height: 64px;
        }
      }
      &.be-lines-2 {
        .be-line-1 {
          // top: 336px;
          // left: 570px;
          // height: 57px;
          // transform: rotate(225deg);

          // dev
          // top: 336px;
          // left: 455px;
          // height: 68px;

          // prod
          top: 336px;
          left: 455px;
          height: 62px;
        }
        .be-line-2 {
          // top: 336px;
          // left: 676px;
          // height: 57px;
          // transform: rotate(225deg);

          // dev
          top: 336px;
          left: 575px;
          height: 64px;

          // prod
          // top: 336px;
          // left: 565px;
          // height: 64px;
        }
      }

      &.be-lines-3 {
        .be-line-1 {
          top: 310px;
          left: 395px;
          height: 110px;
          transform: rotate(231deg);

          // prod
        }
        .be-line-2 {
          left: 511px;
          height: 69px;
          top: 334px;
          // transform: rotate(225deg);

          // prod
        }
        .be-line-3 {
          left: 632px;
          height: 115px;
          top: 311px;
          transform: rotate(125deg);

          // prod
        }
      }
    }
  }
  .be-1 {
    .be-arrow-1 {
      &.down {
        // top: -15px;
        // left: 110px;

        //dev
        top: -15px;
        left: 113px !important;

        //prod
        // top: -15px;
        // left: 131px !important;
      }
    }
  }
  .be-2 {
    .be-arrow-1 {
      //dev
      top: -16px !important;
      left: 167px !important;

      //prod
      // left: 192px !important;

      // top: -16px !important;
      // left: 166px !important;
    }

    .be-arrow-2 {
      left: 71px !important;
    }
  }

  .be-3 {
    .be-arrow-1 {
      // dev
      left: 173px !important;
      top: -14px !important;
      transform: rotate(91deg) !important;

      // prod
      // left: 208px !important;
      // top: -14px !important;
      // transform: rotate(91deg) !important;
    }

    .be-arrow-2 {
      // dev
      top: -12px !important;
      left: 115px !important;

      // prod
      // top: -12px !important;
      // left: 133px !important;
    }

    .be-arrow-3 {
      // dev
      // left: 64px !important;
      // top: -15px !important;
      // transform: rotate(362deg) !important;

      // prod
      left: 67px !important;
      top: -11px !important;
      transform: rotate(362deg) !important;
    }
  }

  .tile-wrapper {
    width: auto;
    position: relative;
    &.be-tile-wrapper {
      .arrow {
        position: absolute;
        &.down {
          top: -15px;
          left: 110px;
        }
      }
    }
  }

  .frontend-area {
    min-height: 150px;
    padding: 50px 0 !important;
  }

  .bff-area {
    position: relative;
    width: 100%;
    z-index: 9;
    .bff-tile {
      position: absolute;
      left: calc(50% - 100px);
      top: -23px;
      width: 220px;
      text-align: center;
      line-height: 70px;
      // background: rgba(199, 232, 172, 0.8);
      margin: 0 auto;
      // border: 2px solid #bbbaba;
      height: 70px;
      font-size: 16px;
      border: 4px;
      font-weight: bold;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4) !important;
      background-color: #9c27b0 !important;
      color: #fff;
      border-radius: 4px;
    }
    .fe-arrow {
      z-index: 100;
      position: absolute;
    }
  }
  .bff-fe-3 {
    .fe-arrow-1 {
      left: 418px;
      top: -38px;
      transform: rotate(1deg) !important;
    }
    .fe-arrow-2 {
      left: 478px;
      top: -39px;
      // transform: rotate(225deg);
    }
    .fe-arrow-3 {
      left: 551px;
      top: -39px;
      transform: rotate(89deg) !important;
    }
  }

  .backend-area {
    margin-top: 30px;
    min-height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dropzone {
    box-sizing: content-box !important;
    background-color: rgba(250, 250, 250, 1);
    // border: 2px dashed #bbbaba;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 5px;
    text-align: center;
    position: relative;
    h5 {
      font-size: 1rem;
      margin: 5px 0px 15px 5px;
      position: absolute;
      text-align: left;
      color: #232323;
      top: 5px;
      left: 5px;
    }
    &.is-active {
      border: 2px solid #9c27b0 !important;
    }
    &.is-started {
      border: 2px dashed #9c27b0;
    }
  }

  .action-area {
    // margin-top: 60px;
    width: 200px;
    order: 2;
    > button {
      position: relative;
      .middle-icon {
        position: absolute;
        top: 12px;
        left: 90px;
      }
      .move-vert-icon {
        position: absolute;
        left: -6px;
        top: 20px;
        font-size: 30px;
      }
      > span {
        display: block;
        margin-top: 22px;
      }
      color: #fff;
      width: 200px;
      cursor: move;
      margin-left: 30px;
      margin-bottom: 15px;
      font-size: 14px;
      height: 80px;
      font-weight: bold;
      background-color: #fafafa;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      // &:nth-child(1) {
      //   margin-left: 20px;
      // }
      // &:nth-child(3) {
      //   width: 21%;
      // }
    }
  }

  .svc-tile {
    // cursor: move;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 50px;
    margin: 0 0 15px 15px;
    font-size: 14px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4) !important;
    background-color: #9c27b0 !important;
    color: #fff;
    border-radius: 4px;
    // &.fe-tile {
    //   color: #fff;
    // }
    // &.be-tile {
    //   color: #fff;
    // }
  }

  .submit-area {
    margin-top: 50px;
    text-align: center;
    width: 1000px;

    .main-btn {
      width: 120px;
    }

    .normal-btn {
      margin-left: 10px;
      padding-left: 37px;
      background-color: rgba(235, 223, 239, 1);
      color: #3f51b5;
    }
  }
}
